import React from "react"

import BookImage from '../components/bookImage';
import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => (
  <Layout>
    <SEO title="About" />
    <div className="summary">
      <p>
        I write code and write about code. I'm the author of
        <a href="https://pragprog.com/book/es6tips/simplifying-javascript">
          Simplifing JavaScript.
        </a>
      </p>
      <p>
        Most blogging is on <a href="https://medium.com/@jsmapr1">Medium.</a>
      </p>
      <p>
        You can also find me on <a href="https://twitter.com/joesmorgan">Twitter.</a>
      </p>
    </div>
    <div style={{width: 300}}>
      <BookImage />
    </div>
  </Layout>
)

export default About
